import useOnScreen from '../../lib/hooks/useOnScreen'
import { styleHeader } from './StyleHeader'
import { useHeader } from './useHeader'
const Header = ({ sectionRefs }) => {
    const { viewPort, isScreenTable, handleNavButton, isViewNavMobile } =
        useHeader()

    const isWhereToUseVisible = useOnScreen(sectionRefs.whereToApplyRef)
    const isHowToUseVisible = useOnScreen(sectionRefs.howToUseRef)
    const isOpportunitiesVisible = useOnScreen(sectionRefs.opportunitiesRef)
    const isPriceVisible = useOnScreen(sectionRefs.priceRef)

    const numberViewBlock = isWhereToUseVisible
        ? 1
        : isHowToUseVisible
        ? 2
        : isOpportunitiesVisible
        ? 3
        : ''
        ? 4
        : isPriceVisible
        ? 5
        : 0

    return (
        <HeaderWrapperSC>
            <CustomContainerSC>
                <LogoWrapperSC href="#title">
                    <LogoSC />
                    <HeaderTitleSC>SheetsGPT</HeaderTitleSC>
                </LogoWrapperSC>
                <NavMenuSC
                    // onClick={(e) => handleNavButton(e)}
                    $viewPort={viewPort}
                    $isViewNavMobile={isViewNavMobile}
                >
                    <NavTagSC
                        onClick={(e) => handleNavButton(e)}
                        href="#whereToUse"
                        isVisible={isWhereToUseVisible && numberViewBlock === 1}
                    >
                        Где применять?
                    </NavTagSC>
                    <NavTagSC
                        onClick={(e) => handleNavButton(e)}
                        href="#howToUse"
                        isVisible={isHowToUseVisible && numberViewBlock === 2}
                    >
                        Как пользоваться?
                    </NavTagSC>
                    <NavTagSC
                        onClick={(e) => handleNavButton(e)}
                        href="#opportunities"
                        isVisible={
                            isOpportunitiesVisible && numberViewBlock === 3
                        }
                    >
                        Возможности
                    </NavTagSC>
                    {/* <NavTagSC
                        onClick={(e) => handleNavButton(e)}
                        href="#howToGet"
                    >
                        Как получить
                    </NavTagSC> */}
                    <NavTagSC
                        onClick={(e) => handleNavButton(e)}
                        href="#price"
                        isVisible={isPriceVisible && numberViewBlock === 5}
                    >
                        Стоимость
                    </NavTagSC>
                    {!isScreenTable && (
                        <>
                            <ButtonsWrapperSC>
                                <HeaderButtonSC
                                    // onClick={(e) => handleNavButton(e)}
                                    href={`https://${process.env.REACT_APP_DOMAIN}/prod/signup`}
                                >
                                    Регистрация
                                </HeaderButtonSC>
                                <HeaderButtonSC
                                    isWhite={true}
                                    // onClick={(e) => handleNavButton(e)}
                                    href={`https://${process.env.REACT_APP_DOMAIN}/prod/signin`}
                                >
                                    Вход
                                </HeaderButtonSC>
                            </ButtonsWrapperSC>

                            <CloseIconSC
                                $viewPort={viewPort}
                                $isViewNavMobile={isViewNavMobile}
                                onClick={handleNavButton}
                            />
                        </>
                    )}
                </NavMenuSC>
                {isScreenTable && (
                    <ButtonsWrapperSC>
                        <AuthButtonSC
                            href={`https://${process.env.REACT_APP_DOMAIN}/prod/signup`}
                            isBackGround={true}
                        >
                            Регистрация
                        </AuthButtonSC>
                        <AuthButtonSC
                            href={`https://${process.env.REACT_APP_DOMAIN}/prod/signin`}
                        >
                            Вход
                        </AuthButtonSC>
                    </ButtonsWrapperSC>
                )}
                {!isScreenTable && (
                    <MobileButtonSC onClick={handleNavButton}>
                        <MobileSpanSC />
                    </MobileButtonSC>
                )}
            </CustomContainerSC>
        </HeaderWrapperSC>
    )
}

const {
    LogoSC,
    NavTagSC,
    NavMenuSC,
    CloseIconSC,
    MobileSpanSC,
    AuthButtonSC,
    HeaderTitleSC,
    LogoWrapperSC,
    HeaderButtonSC,
    MobileButtonSC,
    HeaderWrapperSC,
    ButtonsWrapperSC,
    CustomContainerSC,
} = styleHeader()

export default Header
