import { createPortal } from 'react-dom'
import { ModalConfirmationStyle } from './style'
import { useEffect, useState } from 'react'
import { tokenSend } from '../../lib/http/emailJs'
import { useResize } from '../../lib/hooks/useResize'

const ModalConfirmation = ({ token }) => {
    const [progress, setProgress] = useState({
        status: 'pending',
        icon: <PendingIconSC />,
        progressTitle: 'Пожалуйста, подождите...',
        progressText: 'Подтверждаем Вашу почту',
        progressColor: '#8A8B97',
        isDisableButton: true,
        buttonText: 'Ждем...',
        buttonFuncion: () => {},
    })

    const [isEndAnim, setIsEndAnim] = useState(false)

    const { isScreenMediumMobile } = useResize()
    const okHandle = () => {
        setIsEndAnim(true)

        setTimeout(() => {
            window.location.replace(`https://${process.env.REACT_APP_DOMAIN}`)
        }, 100)
    }
    const errorHandle = () => {
        setProgress({
            status: 'pending',
            icon: <PendingIconSC />,
            progressTitle: 'Пожалуйста, подождите...',
            progressText: 'Подтверждаем Вашу почту',
            progressColor: '#8A8B97',
            isDisableButton: true,
            buttonText: 'Ждем...',
            buttonFuncion: () => {},
        })
        sendFetch(token)
    }

    const sendFetch = async (token) => {
        try {
            await tokenSend(token).then(() => {
                setProgress({
                    status: 'success',
                    icon: <SuccessIconSC />,
                    progressTitle: 'Регистрация завершена!',
                    progressText:
                        'Проверьте почту - там письмо с дальнейшими инструкциями и контактами для связи',
                    buttonText: 'Супер!',
                    isDisableButton: false,
                    progressColor: '#56A2FD',
                    buttonFuncion: okHandle,
                })
            })
        } catch {
            setProgress({
                status: 'error',
                icon: <ErrorIconSC />,
                progressTitle: 'Что-то пошло не так!',
                progressText: 'Попробуйте еще раз, нажав на кнопку ниже',
                buttonText: 'Еще раз',
                isDisableButton: false,
                progressColor: '#DA4B4B',
                buttonFuncion: errorHandle,
            })
        }
    }
    useEffect(() => {
        sendFetch(token)
    }, [])

    return createPortal(
        <>
            <BackgroundSC isEndAnim={isEndAnim}>
                <WrapperSC>
                    <ProgressWrapperSC>
                        <ProgressWrapperTitleSC>
                            {!!isScreenMediumMobile && (
                                <ProgressTitleSC $color="#56A2FD">
                                    Анкета
                                </ProgressTitleSC>
                            )}

                            <ProgressTitleSC $color={progress.progressColor}>
                                Подтверждение
                            </ProgressTitleSC>
                            <ProgressTitleSC $color="#8a8b97">
                                Доступ
                            </ProgressTitleSC>
                        </ProgressWrapperTitleSC>
                        <ProgressWrapperLineSC>
                            {!!isScreenMediumMobile && (
                                <>
                                    <ProgressCircleSC $color="#56A2FD" />
                                    <ProgressLineSC $color="#56A2FD" />
                                </>
                            )}

                            <ProgressLineSC $color={progress.progressColor} />
                            <ProgressCircleSC $color={progress.progressColor} />
                            <ProgressLineSC
                                $color={
                                    progress.status === 'success' &&
                                    progress.progressColor
                                }
                            />
                            <ProgressLineSC
                                $widthCustom={!isScreenMediumMobile && '23px'}
                                $color={
                                    progress.status === 'success' &&
                                    progress.progressColor
                                }
                            />
                            <ProgressIconSC />
                        </ProgressWrapperLineSC>
                    </ProgressWrapperSC>
                    {progress.icon}
                    <ResultTitleSC
                        color={
                            progress.status === 'error' ? '#DA4B4B' : '#56a2fd'
                        }
                    >
                        {progress.progressTitle}
                    </ResultTitleSC>
                    <ResultTextSC>{progress.progressText}</ResultTextSC>
                    <ButtonSC
                        onClick={(e) => {
                            e.preventDefault()
                            progress.buttonFuncion()
                        }}
                    >
                        {progress.buttonText}
                    </ButtonSC>
                </WrapperSC>
            </BackgroundSC>
        </>,
        document.body
    )
}

const {
    ButtonSC,
    WrapperSC,
    ErrorIconSC,
    BackgroundSC,
    ResultTextSC,
    PendingIconSC,
    SuccessIconSC,
    ResultTitleSC,
    ProgressIconSC,
    ProgressLineSC,
    ProgressTitleSC,
    ProgressCircleSC,
    ProgressWrapperSC,
    ProgressWrapperLineSC,
    ProgressWrapperTitleSC,
} = ModalConfirmationStyle()

// export default ModalConfirmation

export default ModalConfirmation
