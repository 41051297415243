import { styleRatesCard } from './StyleRatesCard'

const RatesCard = ({ color, listItem, price, type }) => {
    return (
        <RatesCardWrapperSC $color={color}>
            <RatesTypeSC $color={color}>{type}</RatesTypeSC>
            <PriceWrapperSC>
                <PriceSC>{price}</PriceSC>
                <FreeSC $color={color}>Бесплатно</FreeSC>
            </PriceWrapperSC>
            <ListSC>
                {listItem.map((item) => {
                    return (
                        <ListItemSC key={item}>
                            <ListItemIconSC $color={color} />
                            <ListItemTextSC>{item}</ListItemTextSC>
                        </ListItemSC>
                    )
                })}
            </ListSC>
            <TestButtonSC
                href={`https://${process.env.REACT_APP_DOMAIN}/prod/signup`}
                $color={color}
            >
                <ButtonTextSC>Попробовать</ButtonTextSC>
                <ButtonIconSC />
            </TestButtonSC>
        </RatesCardWrapperSC>
    )
}

const {
    FreeSC,
    ListSC,
    PriceSC,
    ListItemSC,
    RatesTypeSC,
    ButtonIconSC,
    ButtonTextSC,
    TestButtonSC,
    ListItemTextSC,
    ListItemIconSC,
    PriceWrapperSC,
    RatesCardWrapperSC,
} = styleRatesCard()

export default RatesCard
