import { styleTitleBlock } from './StyleTitleBlock'
import { useTitleBlock } from './useTitleBlock'

const TitleBlock = () => {
    const { isScreenXl, isScreenTable, isScreenMobile, isScreenMediumTable } =
        useTitleBlock()

    const img = !isScreenMobile
        ? 'TitleBlockImgMobileSmall.png'
        : !isScreenMediumTable
        ? 'TitleBlockImgMedTable.webp'
        : !isScreenTable
        ? 'TitleBlockImgTable.webp'
        : !isScreenXl
        ? 'TitleBlockImgLaptop.webp'
        : 'TitleBlockImg.png'
    return (
        <TitleBlockSC id="title">
            <TitleBlockWrapperSC>
                <CustomContainerSC>
                    <LeftSideWrapperSC>
                        <TitleSC>
                            Сервис для обработки данных на основе ИИ
                        </TitleSC>
                        <SubTitleSC>
                            Формулируйте запросы текстом и получайте что нужно
                        </SubTitleSC>
                        {isScreenMobile && (
                            <>
                                <ButtonSC
                                    href={`https://${process.env.REACT_APP_DOMAIN}/prod/signup`}
                                >
                                    Присоединиться к тестированию
                                </ButtonSC>
                                <ButtonYoutubeSC
                                    target="_blank"
                                    href="https://www.youtube.com/watch?v=wNtnUYgkZrI&ab_channel=SheetsGPT-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85"
                                >
                                    Видео-обзор
                                </ButtonYoutubeSC>
                            </>
                        )}
                    </LeftSideWrapperSC>
                </CustomContainerSC>
                <ImgSC src={img} alt={'Таблицы'} />
                {!isScreenMobile && (
                    <>
                        <ButtonSC
                            href={`https://${process.env.REACT_APP_DOMAIN}/prod/signup`}
                        >
                            Присоединиться к тестированию
                        </ButtonSC>
                        <ButtonYoutubeSC
                            target="_blank"
                            href="https://www.youtube.com/watch?v=wNtnUYgkZrI&ab_channel=SheetsGPT-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85"
                        >
                            Видео-обзор
                        </ButtonYoutubeSC>
                    </>
                )}
            </TitleBlockWrapperSC>
        </TitleBlockSC>
    )
}

const {
    ImgSC,
    TitleSC,
    ButtonSC,
    SubTitleSC,
    TitleBlockSC,
    ButtonYoutubeSC,
    LeftSideWrapperSC,
    CustomContainerSC,
    TitleBlockWrapperSC,
} = styleTitleBlock()

export default TitleBlock
