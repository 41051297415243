import { styleFooter } from './StyleFooter'

const Footer = () => {
    return (
        <FooterSC>
            <CustomContainerSC>
                <PartWrapperSC>
                    <PartTitleSC>Россия</PartTitleSC>
                    <AddressSC
                        target="_blank"
                        href="https://yandex.ru/maps/-/CCUoaJXCdB"
                    >
                        Челябинск {'\n'} пр. Ленина 87 / 3БВ,{'\n'} 454079
                    </AddressSC>
                </PartWrapperSC>
                <PartWrapperSC>
                    <PartTitleSC>Контакты</PartTitleSC>
                    <ContactItemSC
                        target="_blank"
                        href="mailto:info@stit.online"
                    >
                        info@stit.online
                    </ContactItemSC>
                    <ContactItemSC href="tel:+79995859605">
                        +7(993)934-99-96
                    </ContactItemSC>
                </PartWrapperSC>
                <PartWrapperSC>
                    <PartTitleSC>Соцсети</PartTitleSC>
                    <SocialWrapperSC>
                        <SocialItemSC
                            target="_blank"
                            href="https://vk.com/ant.aversg"
                        >
                            VK
                        </SocialItemSC>
                        <SocialItemSC target="_blank" href="https://t.me/tyfin">
                            TG
                        </SocialItemSC>
                    </SocialWrapperSC>
                </PartWrapperSC>
            </CustomContainerSC>
            <CompanyWrapperSC>
                <CompanySC>
                    <CompanyItemSC>ООО "СТ АЙТИ"</CompanyItemSC>
                    <CompanyItemSC>ИНН 7452164688</CompanyItemSC>
                    <CompanyItemSC>ОГРН 1237400039250</CompanyItemSC>
                </CompanySC>
                <CompanyItemSC>
                    © SheetsGPT 2023. All Rights Reserved
                </CompanyItemSC>
            </CompanyWrapperSC>
        </FooterSC>
    )
}

const {
    FooterSC,
    AddressSC,
    CompanySC,
    PartTitleSC,
    SocialItemSC,
    CompanyItemSC,
    ContactItemSC,
    PartWrapperSC,
    SocialWrapperSC,
    CompanyWrapperSC,
    CustomContainerSC,
} = styleFooter()

export default Footer
